.component-page-title {
    font-size: 26px;
    line-height: 32px;
    font-weight: 400;
    color: #1A1C21;
}

.component-page-title-secondary {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #1A1C21;
}